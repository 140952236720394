import { SET_USER, USER_LOGOUT } from '../actions/types';

const initialState = {
  isLoading: true,
  authorized: false,
  modules: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      const { first_name, last_name, plan_type, modules_submodules, userID, type_user, approverCondition } = action.payload;
      return {
        ...state,
        isLoading: false,
        authorized: true,
        first_name,
        last_name,
        plan_type,
        modules: modules_submodules[0],
        userID,
        isadmin: type_user === "ROLE_ADMIN" ? true : false,
        approverCondition: approverCondition
      };

    case USER_LOGOUT:
      return {
        ...state,
        isLoading: false,
        authorized: false,
      };
    default:
      return state;
  }
};
